import React, { useState, useEffect } from "react";
import { Row, Col, Nav, Container, Button, Spinner } from "react-bootstrap";
import Jump from 'jump.js'

function ActionButton({ label, onClick = null, type, icon, size='lg', jumpTo = null }) {
    const smallButtonStyle = {
        padding: '4px 11px 4px 11px !important',
        fontSize: '6px !important'
    }

    function _click(){
        if(jumpTo){
            Jump(jumpTo)
            return 
        }
        if (onClick){
            onClick()
            return
        }
        
    }
    return (
        <>
            {
                size == 'lg' &&
                <Button variant="light" onClick={_click} type={type}>
                    {/* <div className="d-flex justify-items-between gap-2"> */}
                    {
                        icon &&
                        <i className={`bi bi-${icon} pr-2`}></i>
                    }
                    <span>
                        {label}

                    </span>
                    {/* </div> */}
                </Button>
            }
            {
                size == 'sm' &&
                <Button size="sm" style={smallButtonStyle} variant="light" onClick={_click} type={type}>
                    {/* <div className="d-flex justify-items-between gap-2"> */}
                    {
                        icon &&
                        <i className={`bi bi-${icon} pr-2`}></i>
                    }
                    <span>
                        {label}

                    </span>
                    {/* </div> */}
                </Button>
            }
        </>
        
    )
}

export default ActionButton;
