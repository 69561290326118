import React from "react";
import profilepic from "../profilepic.svg";
import { Row, Col, Container } from "react-bootstrap";
import ActionButton from "../components/ActionButton";
function Home() {
  return (
    <section className="page" id="home" style={{ padding: "20vh 5vw 20vh 5vw" }}>
      <Row>
        <Col>
          <Row>
            {/* <Col xs={3} >
            </Col> */}
            <Col>
              <h1>Hello,</h1>
            </Col>
          </Row>
          <Row>
            {/* <Col xs={1}></Col> */}
            <Col>
              <p>
                I'm Jairus, a person who's fascinated with computers which led me to pursue it as a career.
                In the realm of computers, I'm interested in creating U.I. designs, developing android applications,
                building chrome extensions and tinkering with computer vision and A.I. with python.
                Check out my recent paper that studies the use of BERT NLP to <a href="https://ieeexplore.ieee.org/document/9731956" target="_blank" style={{ textDecoration: "underline" }}>classify fire-related tweets on Twitter.</a>
              </p>
              <a
                href="https://www.instagram.com/jairusmingua"
                target="_blank"
                rel="noreferrer"
                style={{ textDecoration: 'none' }}

                className="fab fa-instagram fa-2x"
                aria-hidden="true"
              >
                { }
              </a>
              <a
                href="https://www.github.com/jairusmingua/"
                target="_blank"
                style={{ textDecoration: 'none' }}

                rel="noreferrer"
                className="fab fa-github fa-2x"
                aria-hidden="true"
              >
                { }
              </a>
            </Col>
            <Col md={7}></Col>
          </Row>
          <Row>
            <Col className="pt-4">
              <ActionButton label={'LET\'S TALK'} icon="chat-right-fill" jumpTo={'#contact'} />
            </Col>
          </Row>
        </Col>
      </Row>
    </section>
  );
}

export default Home;
