import React, { useState } from "react";
import { Row, Col, Container, Form, Button } from "react-bootstrap";
import ActionButton from "../components/ActionButton";
function Contact() {
  const [message, setMessage] = useState("");
  const [subject, setSubject] = useState("RE: Inquiry")
  return (
    <section className="page-flexible" id="contact" style={{ padding: "15vh 5vw 15vh 5vw" }}>
      <Row>
        <Col>
          <Row>
            <Col>
              <h1>Let's talk!</h1>
            </Col>
          </Row>
          <Row>
            <Col>
              <p>If you have questions and inquiries just email me at
                <br />
                <a href="mailto:talk@jairusmingua.xyz">talk@jairusmingua.xyz</a>

              </p>
            </Col>
          </Row>
          <Row className="px-3 py-0">
            <Col className="p-0">
              <Form
                action={`mailto:talk@jairusmingua.xyz?subject=${subject}&body=${message}`}
                className="w-100"
                method="post"
                encType="text/plain"
              >
                <Form.Group controlId="formBasicEmail">
                  <Form.Label>Subject</Form.Label>
                  <Form.Control
                    type="text"
                    placeholder="RE: Inquiry"
                    onChange={(e) => setSubject(e.target.value)}
                  />
                </Form.Group>
                <Form.Group controlId="formBasicEmail">
                  <Form.Label>Message</Form.Label>
                  <Form.Control
                    as="textarea"
                    type="text"
                    placeholder="Write Something..."
                    rows={6}
                    onChange={(e) => setMessage(e.target.value)}
                  />
                </Form.Group>
                <div className="pt-4">
                  <ActionButton label={'SUBMIT'} type={'submit'}></ActionButton>
                </div>
              </Form>
            </Col>
            <Col md={12} lg={7}></Col>
          </Row>
        </Col>
        <Col md={12} lg={4}></Col>
      </Row>
    </section>
  );
}

export default Contact;
