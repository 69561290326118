import logo from "./logo.svg";
import "./App.css";
import Home from "./pages/Home";
import Works from "./pages/Works";
import Contact from "./pages/Contact";
import { useRef, useState, useEffect } from 'react';
import Jump from "jump.js";
import db from "./index";
import {
  Navbar,
  Form,
  FormControl,
  Button,
  Col,
  Row,
  Nav,
  Container,
  Spinner
} from "react-bootstrap";
import ActionButton from "./components/ActionButton";

function App() {
  const [currentPage, setCurrentPage] = useState(0);
  const [pages, setPages] = useState([]);
  const [works, setWorks] = useState([]);
  const [isLoading, setIsLoading] = useState(true);

  useEffect(() => {
    setWorks([])
    setPages([])
    try {
      db.collection("MyWorksV2")
        .get()
        .then((snapshot) => {
          console.log(snapshot)
          snapshot.forEach((doc) => {
            setWorks((works) => works.concat(doc.data()))
            setPages((pages) => pages.concat(doc.data().name))
          });
          setPages((pages) => pages.concat(['home', 'contact']))
          setIsLoading(false)
        });
    } catch (error) {
      console.log(error)
    }
  }, []);

  function _onScrollChange(id, toJump = false) {
    if (toJump) {
      Jump(`#${id}`, {
        duration: 500
      })
    }
  }
  const onScroll = () => {
    const scrolled = document.documentElement.scrollTop
    const maxHeight = document.documentElement.scrollHeight - document.documentElement.clientHeight
    const scrollPercent = parseInt((parseInt((scrolled / maxHeight) * 100) * pages.length - 1) / 100)
    if (scrolled == 0) {
      setCurrentPage(0)
    } else {
      setCurrentPage(scrollPercent)
    }
  };

  window.addEventListener("scroll", onScroll);

  return (
    <div className="App">
      {
        !isLoading ?
          <>
            <div className="fader">

            </div>
            <div className="scroll-observer">
              <li>
                {
                  pages.map((page, i) =>
                    <ul key={i}><i className={`bi bi-circle${page == pages[currentPage] ? '-fill' : ''}`} onClick={() => _onScrollChange(page, true)}></i></ul>
                  )
                }
              </li>
            </div>
            <Navbar variant="dark" style={{ position: "fixed" }}>
              <Container fluid={true} style={{ paddingInline: '3vw' }}>
                <Col>
                  <Navbar.Brand onClick={() => Jump("#home")}>
                    <img
                      alt=""
                      src={logo}
                      width="20"
                      height="30"
                      className="d-inline-block align-top"
                    />{" "}
                  </Navbar.Brand>
                </Col>
                <a
                  href="https://www.facebook.com/JairusMingua"
                  target="_blank"
                  rel="noreferrer"

                  aria-hidden="true"
                >
                  { }
                </a>
              </Container>
            </Navbar>
            <Container fluid={true}>

              <Works pages={pages} currentPage={currentPage} works={works}></Works>
              <Home></Home>
              <Contact></Contact>
              <div className="backdrop" style={{ backgroundImage: 'url(https://cdn.dribbble.com/users/1633085/screenshots/16865805/media/9a1c0e3c73dafd45c8a2804abdc21219.jpg?compress=1&resize=1000x750&vertical=top)' }}>

              </div>
            </Container>
            <footer>
              <Container className="py-4" fluid={true}>
                <Row>
                  <Col>
                    <div className="d-flex justify-content-between">

                      <div className="d-flex justify-content-start align-items-center gap-3">
                        2022 © JAIRUSMINGUA.XYZ
                        <a
                          href="https://www.instagram.com/jairusmingua"
                          target="_blank"
                          rel="noreferrer"
                          style={{ textDecoration: 'none' }}

                          className="fab fa-instagram fa pl-2"
                          aria-hidden="true"
                        >
                          { }
                        </a>
                        <a
                          href="https://www.github.com/jairusmingua/"
                          target="_blank"
                          style={{ textDecoration: 'none' }}

                          rel="noreferrer"
                          className="fab fa-github fa"
                          aria-hidden="true"
                        >
                          { }
                        </a>
                        <ActionButton label={'LET\'S TALK'} icon="chat-right-fill" size={'sm'} jumpTo={'#contact'} />
                      </div>
                    </div>
                  </Col>
                  <Col xs={12}>
                    <div className="d-flex flex-column align-items-start pt-2" style={{ fontSize: "8px" }}>
                      <a href="https://dribbble.com/shots/16865805-3D-Illustration-of-the-logo" target="#blank">
                        <span>3D Illustration of The Logo by Krestovskaya Anna</span>
                      </a>

                    </div>

                  </Col>
                </Row>
              </Container>
            </footer >
          </> :
          <>
            <div className="spinner container d-flex flex-column">
              <Spinner animation="border" role="status">
              </Spinner>
            </div>
            <div className="loading-title">
              <span>jairusmingua.xyz</span>
            </div>
          </>

      }

    </div >

  );
}

export default App;
