import React, { useState, useEffect, useRef } from "react";
import ModalPage from "../components/ModalPage";
import Tile from "../components/Tile";
import { Row, Col, Spinner } from "react-bootstrap";
import ActionButton from "../components/ActionButton";
import { Controller, Scene } from "react-scrollmagic";

function Works({ pages, currentPage, works }) {

  function viewportToPixels(value) {
    var parts = value.match(/([0-9\.]+)(vh|vw)/)
    var q = Number(parts[1])
    var side = window[['innerHeight', 'innerWidth'][['vh', 'vw'].indexOf(parts[2])]]
    return side * (q / 100)
  }

  return (
    <>
      <section
        className="page-flexible"
        style={{ padding: "0vh 5vw 15vh 5vw" }}
      >
        <div className="fader"></div>
        <Controller>
          <Scene pin={true} enabled={true} duration={viewportToPixels('250vh')} offset={0}>

            <div className="work-title">
              <div className="container">

                <Row>
                  <Col className="p-0">
                    <img className="work-logo" src={works[!(currentPage < pages.length - 2) ? pages.length - 3 : currentPage]?.logo}></img>
                    <Row>

                      <Col>
                        <p>{works[!(currentPage < pages.length - 2) ? pages.length - 3 : currentPage]?.description}</p>
                      </Col>
                      <Col></Col>
                    </Row>
                    {/* <ActionButton label={'MORE INFO'} icon="info-circle-fill"></ActionButton> */}
                  </Col>
                </Row>

              </div>
            </div>
          </Scene>
        </Controller>
        {
          works.map((work) =>
            <section className="works" id={work.name}>
              <img className="work-img" src={work.cover2}></img>

            </section>
          )
        }
      </section>
    </>
  );
}

export default Works;
