import React from "react";
import { Col } from "react-bootstrap";
import Project from '../models/Project';
function Tile(props) {
  const project = new Project(props.project);
  return (
    <Col className="tile">
      <div className="title text-center" style={{background:`url("${project.logo}") center/50% no-repeat,black`}}onClick={()=>props.showModal(project)}>
  
      </div>
    </Col>
  );
}

export default Tile;
